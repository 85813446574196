.App {
  text-align: center;
  background-image: url('./assets/images/NETSTREAMCI.png');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  display: block;
  font-size: 3em;
  /* margin-block-start: 0.67em; */
  margin-block-end: 0.2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.talk-to-ai
{
  /* height: 300px; */
  margin:auto !important;
}

.center-box
{
  min-height: 300px;
  max-height: 500px;
}

.block
{
  display: block;
}

.center-icon-wrapper
{
  height: 300px;
  display: inline-flex;
}

.icon-text
{
  font-size: 0.7em;
}

.vertical-center-block
{
  display: flex;
  flex-direction: column; /* 요소들을 세로로 배치 */
  align-items: center;
  justify-content: center; /* 수직 가운데 정렬 */
  padding-left: 40px;
  padding-right: 40px;
}

.recorded-text
{
  font-size: 0.7em;
  color: rgba(235, 235, 150, 1) !important;
}

.recorded-text-helper
{
  text-align: right !important;
  width: 100%;
}

.stop-listening
{
  margin-top: 30px !important;
}

.guide-text
{
  font-size: 0.7em;
  color: rgba(150, 150, 250, 1);

  margin-top: 10px !important;
  white-space: pre-line;
  line-height: 0.8em;
}